export const colors = {
  primary: {
    "100": "rgb(186, 231, 220)",
    "200": "rgb(97, 197, 172)",
    "300": "rgb(37 171 144)",
    "400": "rgb(32 165 133)",
    "500": "rgb(26, 145, 115)",
    "600": "rgb(39, 119, 99)",
    "700": "rgb(28, 95, 78)",
  },
  secondary: {
    "100": "rgb(234, 231, 228)",
    "200": "rgb(190, 173, 161)",
    "300": "rgb(177 147 129)",
    "400": "rgb(159, 129, 111)",
    "500": "rgb(133 111 94)",
    "600": "rgb(128, 105, 90)",
    "700": "rgb(103, 82, 63)",
  },
  gray: {
    "100": "rgb(250, 250, 250)",
    "200": "rgb(240, 240, 240)",
    "300": "rgb(233, 233, 233)",
    "400": "rgb(218, 218, 218)",
    "500": "rgb(192, 192, 192)",
    "600": "rgb(133, 133, 133)",
    "700": "rgb(73, 73, 73)",
  },
};

export const baseSize = 12;

export const sizes = {
  "050": baseSize * 0.5,
  "100": baseSize * 1,
  "150": baseSize * 1.5,
  "200": baseSize * 2,
  "250": baseSize * 2.5,
  "300": baseSize * 3,
  "350": baseSize * 3.5,
  "400": baseSize * 4,
  "450": baseSize * 4.5,
  "500": baseSize * 5,
  "550": baseSize * 5.5,
  "600": baseSize * 6,
  "650": baseSize * 6.5,
  "700": baseSize * 7,
  "750": baseSize * 7.5,
  "800": baseSize * 8,
  "850": baseSize * 8.5,
  "900": baseSize * 9,
  "950": baseSize * 9.5,
  "1000": baseSize * 10,
  "1050": baseSize * 10.5,
  "1100": baseSize * 11,
  "1150": baseSize * 11.5,
  "1200": baseSize * 12,
  "1250": baseSize * 12.5,
  "1300": baseSize * 13,
  "1350": baseSize * 13.5,
  "1400": baseSize * 14,
  "1450": baseSize * 14.5,
  "1500": baseSize * 15,
  "1550": baseSize * 15.5,
  "1600": baseSize * 16,
  "1650": baseSize * 16.5,
};
